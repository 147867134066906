$(document).foundation();

$(document).ready(function(){
  $('.logo-slider').slick({
    centerPadding: '65px',
    arrows: false,
    pauseOnHover: true,
    speed: 8000,
    autoplay: true,
    autoplaySpeed: 0,
    centerMode: true,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: true,
    initialSlide: 1,
    arrows: false,
    buttons: false
  });



  $(window).scroll(function() {
    var scroll = $(window).scrollTop();

    if (scroll >= 50) {
        $("body").addClass("stickyheader");
    } else {
      $("body").removeClass("stickyheader");
    }
  });

  $(".mobile-menu").on("click", (function() {
    $(".hamburger-menu").toggleClass("animate");
    $("#site-navigation .top-bar .top-bar-right .menu").toggleClass("active");
  }))


});
